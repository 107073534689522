import React from "react";
import { Button } from "antd";
import {
    PlusCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    FileAddOutlined,
    FormOutlined,
    FolderAddOutlined,
    EditOutlined,
    DeleteOutlined,
    BugOutlined,
    SyncOutlined
} from "@ant-design/icons";

const ReusableButton = ({ variant, text, icon, href, onClick, className, loading, htmlType, hideIcon }) => {
    const styles = {
        campaign: {
            backgroundColor: "#1DA1F2", // Mavi
            borderColor: "#1DA1F2",
            color: "#FFFFFF",
            fontWeight: "bold",
            // boxShadow: "0 4px 12px rgba(29, 161, 242, 0.6)",
        },
        template: {
            backgroundColor: "#25D366",
            borderColor: "#25D366",
            color: "#FFFFFF",
            fontWeight: "bold",
            // boxShadow: "0 4px 12px rgba(37, 211, 102, 0.6)",
        },
        default: {
            backgroundColor: "#FFFFFF",
            borderColor: "#E0E0E0",
            color: "#000000",
            fontWeight: "normal",
        },
    };

    // İkonun tipi (default değer: PlusCircleOutlined)
    const defaultIcon =hideIcon?null:( icon || <PlusCircleOutlined />);
    const buttonStyle = styles[variant] || styles.default;

    return (
            <Button
                style={{
                    ...buttonStyle,
                    transition: "all 0.3s ease",
                }}
                icon={defaultIcon}
                href={href}
                onClick={onClick}
                block
                className={className}
                loading={loading}
                htmlType={htmlType}
            >
                {text}
            </Button>
      
    );
};

export default ReusableButton;
