import React, { Component, useEffect, useState } from 'react'
import Flex from 'components/shared-components/Flex';
import { Tabs, Form, Button, message, Input, Row, Col, Card, Menu } from 'antd';
import { Progress, Tooltip, Tag, notification } from 'antd';
import { Badge, Descriptions, Table } from 'antd';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import network from 'network/network';
import helperFunc from 'helpers/helperFunc';
import { useNavigate, useParams } from 'react-router-dom';

import { PlusCircleOutlined, ClockCircleOutlined, SearchOutlined, TeamOutlined, RollbackOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReusableButton from 'components/Elements/ReusableButton';


const Groups = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [groupList, setGroupList] = useState([]);
    const [filtredData, setfiltredData] = useState();

    useEffect(() => {
        selectGroupList();
    }, []);

    const onSearch = e => {
        const searchValue = e.currentTarget.value;
        if (searchValue == '') {
            setfiltredData(groupList);
            return;
        }
        const filteredData = groupList.filter(function (el) {
            if (el?.groupdesc != null) {
                return (
                    (el?.groupname?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 ||
                        el?.groupdesc?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
                );
            }
            return (
                (el?.groupname?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
            );
        });
        setfiltredData(filteredData);
    }

    const dropdownMenu = elm => {
        return [
            {
                label: (<span >{t('edit')}</span>),
                key: '3',
                icon: <EditOutlined />,
                onClick: () => navigate(`groups-create/${elm.groupid}`)
            }
        ];
    }


    const [columns, setColumns] = useState([

        {
            width: '20px',

        },
        {
            title: t('groupName'), // 'Grup Adı' için dil desteği eklenmiş
            dataIndex: 'GroupName',
            key: 'groupname',
            width: '300px',
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item">{record.groupname}</div>
                    </div>
                </div>
            )
        },
        {
            title: t('groupDesc'), // 'Firma Adı' için dil desteği eklenmiş
            dataIndex: 'firmname',
            key: 'firmname',
            width: '300px',
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item  ">{record.groupdesc}</div>
                    </div>
                </div>
            )
        },
        {
            title: t('customerCount'), // 'Müşteri Sayısı' için dil desteği eklenmiş
            dataIndex: 'customerCount',
            key: 'customerCount',
            width: '150px',
            align: 'center',
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item">{record.customercount}</div>
                    </div>
                </div>
            )
        },
        {
            title: t('status'), // 'Durumu' için dil desteği eklenmiş
            dataIndex: 'id',
            width: 250,
            align: 'center',
            render: (_, elm) => {
                return (
                    elm.status ? (
                        <Tooltip>
                            <div className='my-4 '>
                                <><Tag color={"green"}>{t('active')}</Tag></>
                            </div>
                        </Tooltip>

                    ) : (
                        <Tooltip>
                            <div className='my-4 '>
                                <><Tag color={"red"}>{t('inactive')}</Tag></>
                            </div>
                        </Tooltip>
                    )


                )
            }
        },
        {
            title: t('creationDate'), // 'Oluşturulma Tarihi' için dil desteği eklenmiş
            dataIndex: 'id',
            width: 250,
            align: 'center',
            render: (_, elm) => {
                return (
                    <div className='my-4 '>
                        {elm?.createdDate && moment(elm?.createdDate).format('MM-DD-YYYY')}
                    </div>
                )
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            width: 50,
            align: 'center',
            render: (_, elm) => (
                <div className="text-right">
                    <EllipsisDropdown menu={{ items: dropdownMenu(elm) }} />
                </div>
            )
        }


    ])

    const selectGroupList = async () => {

        var params = {
        }

        var result = await network.get("/admin/Group/selectGroupList", params, false);
        const { data } = result;
        if (result.isSuccess && data.isSuccess) {
            var groupList = data.data.filter(s => s.groupid != -1);
            setGroupList(groupList);
            setfiltredData(groupList);
        }
    }
    return (
        <div>
            <Card title={t('groupInfo')}>
                <Flex className="mt-4" alignItems="center" justifyContent="between" mobileFlex={false}>
                    <Flex className="mb-1" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Input
                                placeholder={t('searchInGroups')} // 'Gruplarda Ara' için dil desteği eklenmiş
                                prefix={<SearchOutlined />}
                                onChange={e => onSearch(e)}
                            />
                        </div>
                    </Flex>
                    <Flex className="mb-1" mobileFlex={false}>
                    </Flex>
                    <Flex className="mb-1" alignItems="center" justifyContent="between" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                        <ReusableButton
									variant="campaign"
									text={t('newGroup')} 
									icon={<PlusCircleOutlined />}
									className={"mr-2"}
                                    href={"groups/groups-create/0"}
								/>
                            {/* <Button href={"groups/groups-create/0"} type="primary" icon={<PlusCircleOutlined />} block> {t('newGroup')}</Button> */}
                        </div>
                    </Flex>
                </Flex>
                <Col span={24}><Table
                    columns={columns}
                    // loading={props.isLoading}
                    dataSource={filtredData}
                    rowKey='id'
                /></Col>
            </Card>
        </div>
    );
}

export default Groups 