import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { FIRM_ID, LANGUAGE } from 'constants/StorageConstant';

const firmLanguageMap = {
    "4": "tr",
    "1": "tr"
};

const resources = {
    en: {
        translation: {
            "newCustomer": "New Customer",
            "stampCount": "Stamp Count",
            "coffeeGift": "Coffee Gift",
            "activeCustomer": "Active Customer Ratio",
            "top10Stamps": "Number of Stamps Top 10",
            "last10Process": "Last 10 Customer Process",
            "today": "Today",
            "last7Days": "Last 7 Days",
            "currentMonth": "Current Month",
            "previousMonth": "Previous Month",
            "allTime": "All Time",
            "all": "All",
            "Stamp": "Stamp",
            "Card": "Card",
            "stampCancelled": "Stamp Cancelled",
            "stampAccepted": "Stamp Accepted",
            "stampRequestWait": "Stamp Request Waiting",
            "Customers": "Customers",
            "menu": {
                "title": "Menu",
                "dashboard": "Dashboard",
                "customers": "Customers",
                "baristaScreen": "Barista Screen",
                "campaigns": "Campaign List",
                "templates": "Template List",
                "Groups": "Groups",
                "campaignstitle": "Campaigns"
            },
            "customerName": "Customer Name",
            "customerNumber": "Customer Phone Number",
            "regDate": "Registration Date",
            "Branch": "Branch",
            "customerDetail": "Customer Detail",
            "Export": "Export To Excel",
            "customerPermission": "Consent for Communication",
            "customerGroups": "Customer Groups",
            "Group": "Group",
            "cardNumber": "Card Number",
            "annualStampCustomer": "Annual Stamp Chart",
            "Email": "Email",
            "Password": "Password",
            "Login": "Login",
            "WrongEmail": "Please write your email address",
            "WrongEmailFormat": "Please enter a valid Email address!",
            "WrongPassword": "Please Write Your Password",
            "notAvaiable": "Not Avaiable",
            "emptyName": "Name field cannot be left blank",
            "wrongMailFormat": "Wrong mail format",
            "Error": "Error",
            "stampInfo": "Stamp Info",
            "customerInfo": "Customer Info",
            "Transactions": "Transactions",
            "stampConfirmationScreen": "Stamp Confirmation Screen",
            "freeCoffeMessage": "The customer is entitled to receive a free coffee. You can give the free coffee!",
            "cancelCoffeeMessage": "The customer's stamp request will be canceled. Do you want to proceed?",
            "confirmCoffeeMessage": "The customer’s stamp request will be confirmed. Would you like to proceed?",
            "Close": "Close",
            "Cancel": "Cancel",
            "Confirm": "Confirm",
            "branchDistribution": "Branch Distribution",
            "registeredBranch": "Registered Branch",
            "customerInfoCard": "Customer Info Card",
            "giftedCoffee": "Gifted Coffee",
            "Logout": "Log Out",
            "privacyTerms": "Privacy and Terms",
            "searchInCustomers": "Search in Customers",
            "stampRequest": "Stamp Request",
            "stampReqCome": "New stamp request!",
            "stampOkey": "The stamp was printed successfully.",
            "Succesful": "Successful",
            "Confirmation": "Confirmation",
            "cancelReason": "Please select the reason for cancellation :",
            "Cancellation": "Cancellation",
            "selectProduct": "Please choose a product before stamp confirmation",
            "campaignList": "Campaign List",
            "remainingWhatsappMessage": "Remaining Number of Whatsapp Messages Sent",
            "searchInCampaign": "Search in Campaigns",
            "filter": "Filter",
            "campaignName": "Campaign Name",
            "createdDate": "Created Date",
            "newCampaign": "New Campaign",
            "newTemplate": "New Template",
            "campaignDetails": "Campaign Details",
            "sentGroups": "Sent Groups",
            "sentDetails": "Sent Details",
            "recipient": "Recipients",
            "delivered": "Delivered",
            "read": "Read",
            "sent": "Sent",
            "notDelivered": "Not Delivered",
            "senderNumber": "Sender Number",
            "createdTime": "Created Time",
            "recipientInfo": "Recipients Info",
            "phoneNumber": "Phone Number",
            "replybuttondesc": "Feedback",
            "searchNameNumber": "Search in Customer Name/Phone Number",
            "status": "Status",
            "statusDate": "Status Date",
            "statusTime": "Status Time",
            "campaignStatistics": "Campaign Statistics",
            "statistics": "Statistics",
            "showCampaignDetail": "Show Campaign Statistics",
            "newCampaignName": "New Campaign Name",
            "enterCampaginName": "Enter New Campaign Name",
            "forwardAllRecipient": "Forward to all recipients",
            "sender": "Sender",
            "numberToSend": "Please select a sender number to send your campaign messages",
            "numberToSend2": "Sender Number",
            "sendCampaignnInfo": "To schedule a campaign you need to connect your WhatsApp Business account",
            "whichGroupsSendMessage": "Please select the recipients to whom you want to send your campaign messages.",
            "groupsSend": "Groups to Send",
            "messageTemplate": "Message Template",
            "templateName": "Template Name",
            "templateType": "Template Type",
            "messageContent": "Message Content",
            "searchTemplate": "Search Template",
            "templateInfo": "Please select the message template you want to send.",
            "category": "Category",
            "language": "Language",
            "content": "Content",
            "designContentMessage": "Design the Content of Your Campaign Message.",
            "testMessage": "Test Message",
            "testMessageInfo": "Test your campaign message before sending.",
            "testMessageEnter": "Enter the number you will test...",
            "sendTestMessage": "Send Test Message",
            "campaignNotSendError": "Your message could not be sent",
            "campaignNotSendError2": "Please fill in the required fields.",
            "campaignSavedSuccess": "The campaign has been successfully saved.",
            "messageSendSuccess": "Your message has been sent successfully.",
            "createTemplate": "Create Template",
            "editTemplate": "Edit Template",
            "templateList": "Template Message List",
            "templateNameInfo1": "Give your message template a name..",
            "templateNameInfo2": "This is a field that will not be visible to you but is required when sending for WhatsApp.",
            "templateNameInfo3": "Template names can only contain lowercase letters, numbers, and underscores (_).",
            "required": "Required",
            "languageDescription": "Please select the languages in which you will send your template.",
            "languageInfo": "You can select the languages for which you will send your template.",
            "headerTitle": "Header",
            "headerDescription": "Add a 60-character title to your message. Only one variable is supported in the header.",
            "headerInfoMessage": "Image, document, and video will be considered as media types.",
            "templateContentTitle": "Template Content",
            "templateContentDescription": "Enter the message content.",
            "addVariable": "Add Variable",
            "footerTitle": "Footer",
            "footerDescription": "Add a footer to your message with a maximum of 60 characters. Variables are not supported in the footer.",
            "footerDescription2": "The footer section now contains a fixed message and cannot be modified.",
            "tooltipInfo": "You can add up to 10 Quick Reply Buttons, 1 Phone Number, and 2 URLs.",
            "buttonSelectionInfo": "Please select the buttons to add to the template. You can select up to 10 buttons.",
            "quickReplyButton": "Quick Reply Button",
            "urlButton": "URL Button",
            "phoneButton": "Phone Button",
            "inputPlaceholder": "Enter a value as an example.",
            "variable": "Variable {{count}}",
            "quickReplyPlaceholder": "Enter quick reply title",
            "urlButtonTitle": "Enter URL button title",
            "urlButtonValue": "Enter URL button value",
            "phoneButtonTitle": "Enter phone button title",
            "phoneButtonValue": "Enter phone button value",
            "optional": "Optional",
            "buttonsTitle": "Buttons",
            "successMessage": "Success",
            "groupDeleteSuccess": "Group deletion was successfully completed.",
            "errorMessage": "Error!",
            "deleteGroupConfirmation": "group will be permanently deleted. Do you want to continue?",
            "reminder": "Reminder",
            "editButtonLabel": "Edit",
            "edit": "Edit",
            "delete": "Delete",
            "groupName": "Group Name",
            "firmName": "Firm Name",
            "customerCount": "Customer Count",
            "status": "Status",
            "sendDate": "Send Date",
            "readDate": "Read Date",
            "active": "Active",
            "creationDate": "Creation Date",
            "searchInGroups": "Search in Groups",
            "createGroup": "Create Group",
            "successful": "Success",
            "templateDeleteSuccess": "Template deletion was successful.",
            "error": "Error",
            "templateDeleteConfirmation": "Template will be permanently deleted. Do you want to continue?",
            "reminder": "Reminder",
            "edit": "Edit",
            "delete": "Delete",
            "category": "Category",
            "message": "Message",
            "status": "Status",
            "approved": "Approved",
            "rejected": "Rejected",
            "failed": "Failed",
            "pending": "Pending",
            "inactive": "Inactive",
            "campaignList": "Campaign List",
            "newTemplate": "New Template",
            "Search": "Search",
            "updateCustomer": "Update Customer",
            "addCustomer": "Add Customer",
            "update": "Update",
            "add": "Add",
            "generalInfo": "General Information",
            "customerInfo": "Customer Information",
            "customerName": "Customer Name",
            "customerGroup": "Customer Group",
            "email": "Email",
            "branch": "Branch",
            "countryCode": "Country Code",
            "phoneNumber": "Phone Number",
            "replybuttondesc": "Feedback",
            "newCampaign": "New Campaign",
            "newTemplate": "New Template",
            "receiver": "Receiver",
            "sent": "Sent",
            "delivered": "Delivered",
            "read": "Read",
            "notDelivered": "Not Delivered",
            "senderNumber": "Sender Number",
            "targetGroups": "Target Groups",
            "totalBuyerCount": "Total Recipients Count",
            "phoneNumberInstruction": "Enter the phone number to test, including the country code (e.g., '44**********').",
            "enterPhoneNumber": "Enter the phone number...",
            "sendCampaign": "Send Campaign",
            "messageSentSuccessfully": "Your message has been sent successfully.",
            "sendMessage": "Send Message",
            "newGroup": "New Group",
            "active": "Active",
            "inactive": "Inactive",
            "groupInfo": "Group Info",
            "create": "Create",
            "imageSizeError": "Image file cannot exceed 5 MB.",
            "videoSizeError": "Video file cannot exceed 16 MB.",
            "documentSizeError": "Document file cannot exceed 100 MB.",
            "invalidFileFormat": "Invalid file format. Only JPEG, PNG, MP4, and PDF files can be uploaded.",
            "none": "None",
            "text": "Text",
            "photo": "Photo",
            "video": "Video",
            "document": "Document",
            "templateCreatedSuccessfully": "Template has been created successfully.",
            "uploadPhoto": "Upload Photo",
            "uploadVideo": "Upload Video",
            "uploadDocument": "Upload Document",
            "titlePlaceholder": "Enter the title here",
            "templateVariablesRequired": "Template variables are required.",
            "templateNameRequired": "Template name is required.",
            "languageSelectionRequired": "Language selection is required.",
            "templateContentRequired": "Template content is required.",
            "note": "Note:",
            "fileFormatsAndSizes": "Below are the accepted file formats and sizes for upload:",
            "image": "Image",
            "jpegPng": "JPEG and PNG",
            "maxSize": "Maximum size",
            "updateGroup": "Update Group",
            "addGroup": "Add Group",
            "groupInfo": "Group Information",
            "groupName": "Group Name",
            "groupDesc": "Group Description",
            "active": "Active",
            "save": "Save",
            "groupNamePlaceholder": "Enter Group Name",
            "groupDescPlaceholder": "Enter Group Description",
            "requiredFields": "Please fill out the required fields",
            "groupSavedSuccess": "Group information has been successfully saved.",
            "send": "Send",
            "customerConfirmationError": "You have unsaved data. Do you want to continue without saving?",
            "warning": "Warning!",
            "dataLoading": "Data is loading...",
            "turkish": "Turkish",
            "backToTemplate": "Template List",
            "createTemplateText": "Save And Submit",
            "importExcel": "Import From Excel",
            "importExcelFile": "Import Excel File",
            "downloadSampleExcel": "Download Sample File",
            "customerSaveSuccess": "Customer information has been successfully saved.",
            "customerSaveError": "An error occurred while saving customer information. Please try again.",
            "requiredFieldsError": "Please fill in the required fields.",
            "clientApprovedText": "The user's communication consent was obtained via WhatsApp.",
            "clientApprovedTextByAdmin": "The user's communication consent was granted by the admin.",
            "clientDenyText": "The user's communication consent was revoked via WhatsApp.",
            "clientDenyByAdminText": "The user's communication consent was revoked by the admin.",
            "denyClient": "Reject Approval",
            "denyClientConfirmTitle": "The customer's communication consent will be revoked.",
            "denyClientConfirmContent": "You will not be able to send messages to the user once the customer's communication consent is revoked. Do you want to proceed?",
            "apiSuccess": "The action was completed successfully",
            "apiError": "An error occurred. Please try again",
            "allReceiver": "All Recipients",
            "pleaseSelect": "Please select",
            "emptyExcelError": "The Excel file is empty. Please upload a valid file.",
            "excelSuccessLoad": "The file was uploaded successfully",
            "matchExcelColumns": "Match Excel Columns",
            "excelColumn": "Excel Column",
            "matchedExcelColumn": "Match Column",
            "sampleExcelData": {
                "customerName": "John Doe",
                "email": "john.doe@example.com",
                "phone": "1234567890",
                "customerGroup": "Test Group",
                "countryCode": "44",

            },
            "sampleExcelFileName": "Customer List",
            "fileName": "File Name",
            "totalRecord": "Total Record Count",
            "missingNumberCount": "Missing Data Count",
            "missingPhoneNumberDesc": "Missing data in the Phone Number column",
            "uploadFileDetailTitle": "Uploaded File Details",
            "saveExcelCustomersButton": "Complete Upload",
            "uploadMatchError": "Not all columns have been matched. Please match the missing columns and try again",
            "excelSaveSuccess": "The save operation was completed successfully.",
            "excelSaveError": "Data could not be saved. Please try again.",
            "forgotPassword": "Forgot Password",
            "resetPassword": "Reset Password",
            "noUserError": "No user found with this email address. Please check and try again.",
            "emailSendError": "An error occurred while sending the email. Please try again later.",
            "networkError": "A network error occurred. Please check your internet connection and try again.",
            "forgotPassSuccess": "Password reset link has been successfully sent to your email address. Please check your inbox.",
            "resetTokenNotFound": "Invalid link. Password reset request not found.",
            "resetTokenExpire": "This link has expired. Please request a new password reset link.",
            "resetTokenCompleted": "This link has already been used. Please request a new password reset link",
            "changePassword": "Update Password",
            "newPassword": "New password",
            "newPasswordAgain": "Re-enter new password",
            "passwordMatchError": "Passwords do not match. Please try again",
            "resetPasswordCompletedSuccess": "Your password has been successfully updated. You can now log in. You will be redirected to the login page shortly.",
            "resetPasswordCompletedError": "An error occurred while resetting your password. Please try again.",
            "errorCode": "Error Code",
            "errorMessage": "Error Message",
            "stopTemplateMessage": "To stop receiving these messages, reply with CANCEL",
            "creditOverflow": "A maximum of {total} credits can be used.",
            "creditOverflowApiError": "The number of customers selected exceeds your credit limit. Please increase your credit balance and try again.",
            "excelDraggerTitle": "Click or drag a file here to upload.",
            "excelDraggerContent": "Only MS Excel file types (xls, xlsx) are supported.",
            // "campaignSendConfirmationFirst": "The selected sender number",
            // "campaignSendConfirmationSecond": " will send the following message to a total of",
            // "campaignSendConfirmationThird": " recipients. Do you want to proceed?",
            "campaignSendConfirmationTitle": "Your Messages Are Ready For Sending",
            "totalRecipent": "Total Recipients",
            "totalSent": "Total Sent",
            "totalDelivered": "Total Delivered",
            "totalNotSent": "Total Not Sent",
            "totalRead": "Total Read",
            "campaignSendConfirmationFirst": "A total of",
            "campaignSendConfirmationSecond": " recipients will receive your campaign messages. You can complete your process by clicking the send button.",
            "clientStatusUpdateSuccess": "Customer status has been successfully updated",
            "clientStatusUpdateError": "Failed to update customer status. Please try again",
            "makePassive": "Set to Inactive",
            "makeActive": "Set to Active",
            "makePassiveConfirmMessage": "The customer status will be changed to Inactive. Do you want to proceed?",
            "makeActiveConfirmMessage": "The customer status will be changed to Active. Do you want to proceed?"

        }
    },
    tr: {
        translation: {
            "newCustomer": "Yeni Müşteri",
            "stampCount": "Damga Sayısı",
            "coffeeGift": "Hediye Kahve",
            "activeCustomer": "Aktif Müşteri Oranı",
            "top10Stamps": "Damga Sayısı İlk 10",
            "last10Process": "Son 10 Müşteri İşlemi",
            "today": "Bugün",
            "last7Days": "Son 7 Gün",
            "currentMonth": "Bu Ay",
            "previousMonth": "Geçen Ay",
            "allTime": "Tüm Zamanlar",
            "all": "Tümü",
            "Stamp": "Damga",
            "Card": "Kart",
            "stampCancelled": "Damga İptal Edildi",
            "stampAccepted": "Damga Onaylandı",
            "stampRequestWait": "Damga İsteği Beklemede",
            "Customers": "Müşteriler",
            "menu": {
                "title": "Menü",
                "dashboard": "Gösterge Paneli",
                "customers": "Müşteriler",
                "baristaScreen": "Barista Ekranı",
                "campaigns": "Kampanyalar",
                "templates": "Mesaj Şablonları",
                "Groups": "Gruplar",
                "campaignstitle": "Kampanya"
            },
            "customerName": "Müşteri İsmi",
            "customerNumber": "Müşteri Telefon Numarası",
            "regDate": "Kayıt Tarihi",
            "Branch": "Şube",
            "customerDetail": "Müşteri Detay",
            "Export": "Dışa Aktar",
            "customerPermission": "Müşteri İletişim İzni",
            "customerGroups": "Müşteri Grupları",
            "Group": "Grup",
            "cardNumber": "Kart Sayısı",
            "annualStampCustomer": "Yıllık Müşteri Damga Grafiği",
            "Email": "Email",
            "Password": "Şifre",
            "Login": "Giriş Yap",
            "WrongEmail": "Lütfen Email adresinizi giriniz",
            "WrongEmailFormat": "Lütfen geçerli bir Email adresi giriniz!",
            "WrongPassword": "Lütfen Şifrenizi Giriniz",
            "notAvaiable": "Mevcut Değil",
            "emptyName": "İsim kısmı boş bırakılamaz",
            "wrongMailFormat": "Email formatı doğru değil",
            "Error": "Hata",
            "stampInfo": "Damga Bilgisi",
            "customerInfo": "Müşteri Bilgisi",
            "Transactions": "İşlemler",
            "stampConfirmationScreen": "Damga Onay Ekranı",
            "freeCoffeMessage": "Müşteri hediye kahve almaya hak kazandı. Hediye kahvesini verebilirsin!",
            "cancelCoffeeMessage": "Müşterinin damga isteği iptal edilecek devam edilsin mi ?",
            "confirmCoffeeMessage": "Müşterinin damga isteği kabul edilecek devam edilsin mi?",
            "Close": "Kapat",
            "Cancel": "İptal et",
            "Confirm": "Onayla",
            "branchDistribution": "Şube Dağılım",
            "registeredBranch": "Kayıtlı Olduğu Şube",
            "customerInfoCard": "Müşteri Bilgi Kartı",
            "giftedCoffee": "Hediye Kahve",
            "Logout": "Çıkış Yap",
            "privacyTerms": "Gizlilik ve Koşullar",
            "searchInCustomers": "Müşterilerde Ara",
            "stampRequest": "Damga Talebi",
            "stampReqCome": "Yeni damga talebi!",
            "stampOkey": "Damga başarılı bir şekilde basıldı.",
            "Succesful": "İşlem Başarılı",
            "Confirmation": "Onayla",
            "cancelReason": "Lütfen iptal etme sebebini seçiniz :",
            "Cancellation": "İptal",
            "selectProduct": "Lütfen devam etmeden bir ürün seçiniz.",
            "campaignList": "Kampanya Listesi",
            "remainingWhatsappMessage": "Kalan Whatsapp Mesaj Gönderim Adedi",
            "searchInCampaign": "Kampanyalarda Ara",
            "filter": "Sıralama",
            "campaignName": "Kampanya Adı",
            "createdDate": "Oluşturulma Tarihi",
            "newCampaign": "Yeni Kampanya",
            "newTemplate": "Yeni Şablon",
            "campaignDetails": "Kampanya Detayları",
            "sentGroups": "Gönderilen Gruplar",
            "sentDetails": "Gönderim Detayları",
            "recipient": "Alıcı",
            "delivered": "Teslim Edilen",
            "read": "Okunan",
            "sent": "Gönderildi",
            "notDelivered": "İletilmedi",
            "senderNumber": "Gönderilen Numara",
            "createdTime": "Oluşturulma Saati",
            "recipientInfo": "Alıcı Bilgisi",
            "phoneNumber": "Telefon Numarası",
            "replybuttondesc": "Müşteri Yanıtı",
            "searchNameNumber": "Müşteri Adı/ Telefon Numarası Ara",
            "status": "Durumu",
            "statusDate": "Durum Tarihi",
            "statusTime": "Durum Saati",
            "campaignStatistics": "Kampanya İstatikleri",
            "statistics": "İstatistikler",
            "showCampaignDetail": "Kampanya İstatiklerini Göster",
            "newCampaignName": "Yeni Kampanya Adı",
            "enterCampaginName": "Yeni Kampanya Adını Giriniz",
            "forwardAllRecipient": "Tüm Alıcılara İlet",
            "sender": "Gönderen",
            "numberToSend": "Kampanya mesajlarınızı göndermek için bir gönderici numarası seçiniz.",
            "sendCampaignnInfo": "Bir kampanya planlamak için WhatsApp Business hesabınızı bağlamanız gereklidir",
            "whichGroupsSendMessage": "Kampanya mesajlarını göndermek istediğiniz alıcıları seçiniz.",
            "groupsSend": "Gönderilecek Gruplar",
            "messageTemplate": "Mesaj Şablon",
            "templateName": "Şablon Adı",
            "templateType": "Şablon Tipi",
            "templateInfo": "Lütfen göndermek istediğiniz mesaj şablonunu seçiniz.",
            "messageContent": "Mesaj İçeriği",
            "searchTemplate": "Şablon Ara",
            "category": "Kategori",
            "language": "Dil",
            "content": "İçerik",
            "designContentMessage": "Kampanya mesajınızın içeriğini tasarlayın.",
            "testMessage": "Test Mesajı",
            "testMessageInfo": "Göndermeden önce kampanya mesajınızı test edin.",
            "testMessageEnter": "Test edeceğiniz numarayı giriniz...",
            "sendTestMessage": "Test Mesajını Gönder",
            "campaignNotSendError": "Mesajınız Gönderilemedi",
            "campaignNotSendError2": "Lütfen zorunlu alanları doldurunuz..",
            "campaignSavedSuccess": "Kampanya başarıyla kaydedilmiştir.",
            "messageSendSuccess": "Mesajınız başarıyla gönderilmiştir.",
            "createTemplate": "Şablon Oluştur",
            "editTemplate": "Şablon Düzenle",
            "templateList": "Şablon Mesajı Listesi",
            "required": "Zorunlu",
            "templateNameInfo1": "Mesaj şablonunuza bir ad verin.",
            "templateNameInfo2": "Bu, size görünmeyecek ancak WhatsApp için gönderim sırasında gerekli bir alandır.",
            "templateNameInfo3": "Şablon adları yalnızca küçük harfler, rakamlar ve alt çizgi (_) içerebilir.",
            "languageDescription": "Lütfen şablonunuzu hangi dillerde göndereceğinizi seçin.",
            "languageInfo": "Şablonunuzu göndereceğiniz dilleri seçebilirsiniz.",
            "headerTitle": "Başlık",
            "headerDescription": "Mesajınıza 60 karakterlik bir başlık ekleyin. Başlıkta yalnızca bir değişken desteklenmektedir.",
            "headerInfoMessage": "Görüntü, belge ve video, Medya türü olarak kabul edilecektir.",
            "templateContentTitle": "Şablon İçeriği",
            "templateContentDescription": "Mesaj içeriğini giriniz.",
            "addVariable": "Değişken Ekle",
            "footerTitle": "Alt Bilgi",
            "footerDescription": "Mesajınıza 60 karakterlik bir Alt bilgi ekleyin. Alt bilgi içinde değişkenler desteklenmemektedir.",
            "footerDescription2": "Footer alanı artık sabit bir açıklama içermektedir ve değiştirilemez.",
            "tooltipInfo": "Maksimum 1 Telefon Numarası ve 2 URL ekleyebilirsiniz.",
            "buttonSelectionInfo": "Lütfen şablona eklemek için butonları seçin. En fazla 10 buton seçebilirsiniz.",
            "quickReplyButton": "Hızlı Cevap Buton",
            "urlButton": "URL Buton",
            "phoneButton": "Telefon Buton",
            "inputPlaceholder": "Örnek olarak bir değer giriniz.",
            "variable": "Değişken {{count}}",
            "quickReplyPlaceholder": "Hızlı cevap başlığını giriniz",
            "urlButtonTitle": "Url buton başlığını giriniz.",
            "urlButtonValue": "Url buton değerini giriniz.",
            "phoneButtonTitle": "Telefon buton başlığını giriniz.",
            "phoneButtonValue": "Telefon buton değerini giriniz.",
            "optional": "Opsiyonel",
            "buttonsTitle": "Butonlar",
            "successMessage": "Başarılı",
            "groupDeleteSuccess": "Group silme işlemi başarılı bir şekilde gerçekleştirildi.",
            "errorMessage": "Hata!",
            "deleteGroupConfirmation": "adlı grup kalıcı olarak silinecek. Devam edilsin mi?",
            "reminder": "Hatırlatma",
            "editButtonLabel": "Düzenle",
            "edit": "Düzenle",
            "delete": "Sil",
            "groupName": "Grup Adı",
            "firmName": "Firma Adı",
            "customerCount": "Müşteri Sayısı",
            "status": "Durumu",
            "sendDate": "Gönd. Tarihi",
            "readDate": "Okunma Tarihi",
            "active": "Aktif",
            "creationDate": "Oluşturulma Tarihi",
            "searchInGroups": "Gruplarda Ara",
            "createGroup": "Grup Oluştur",
            "successful": "Başarılı",
            "templateDeleteSuccess": "Template silme işlemi başarılı bir şekilde gerçekleştirildi",
            "error": "Hata!",
            "templateDeleteConfirmation": "Template kalıcı olarak silinecek. Devam edilsin mi?",
            "reminder": "Hatırlatma",
            "edit": "Düzenle",
            "delete": "Sil",
            "templateName": "Template Adı",
            "category": "Kategori",
            "templateType": "Template Tipi",
            "message": "Mesaj",
            "status": "Durumu",
            "approved": "Onaylandı",
            "rejected": "Reddedildi",
            "failed": "Hatalı",
            "pending": "Onay Bekliyor",
            "inactive": "Pasif",
            "campaignList": "Kampanya Listesi",
            "newTemplate": "Yeni Template",
            "Search": "Ara",
            "updateCustomer": "Müşteriyi Güncelle",
            "addCustomer": "Yeni Müşteri",
            "update": "Güncelle",
            "add": "Ekle",
            "generalInfo": "Genel Bilgiler",
            "customerInfo": "Müşteri Bilgileri",
            "customerName": "Müşteri Adı",
            "customerGroup": "Müşteri Grubu",
            "email": "Email",
            "branch": "Şube",
            "countryCode": "Ülke Kodu",
            "phoneNumber": "Telefon Numarası",
            "replybuttondesc": "Müşteri Yanıtı",
            "newCampaign": "Yeni Kampanya",
            "newTemplate": "Yeni Şablon",
            "receiver": "Alıcı",
            "sent": "Gönderildi",
            "delivered": "Teslim Edildi",
            "read": "Okundu",
            "notDelivered": "İletilemedi",
            "senderNumber": "Gönderen Numara",
            "targetGroups": "Gönderilecek Gruplar",
            "totalBuyerCount": "Toplam Alıcı Sayısı",
            "phoneNumberInstruction": "Test etmek için ülke kodu dahil telefon numarasını girin (ör. '90**********').",
            "enterPhoneNumber": "Numarayı giriniz...",
            "sendCampaign": "Kampanyayı Gönder",
            "messageSentSuccessfully": "Mesajınız başarıyla gönderilmiştir.",
            "sendMessage": "Mesaj Gönder",
            "newGroup": "Yeni Grup",
            "active": "Aktif",
            "inactive": "Pasif",
            "groupInfo": "Grup Bilgisi",
            "create": "Oluştur",
            "imageSizeError": "Resim dosyası 5 MB'yi geçemez.",
            "videoSizeError": "Video dosyası 16 MB'yi geçemez.",
            "documentSizeError": "Belge dosyası 100 MB'yi geçemez.",
            "invalidFileFormat": "Geçersiz dosya formatı. Yalnızca JPEG, PNG, MP4 ve PDF dosyaları yüklenebilir.",
            "none": "Yok",
            "text": "Metin",
            "photo": "Fotoğraf",
            "video": "Video",
            "document": "Belge",
            "templateCreatedSuccessfully": "Template başarılı bir şekilde oluşturulmuştur.",
            "uploadPhoto": "Fotoğraf Yükle",
            "uploadVideo": "Video Yükle",
            "uploadDocument": "Belge Yükle",
            "titlePlaceholder": "Başlığı buraya yazınız",
            "templateVariablesRequired": "Şablon değişkenleri zorunludur.",
            "templateNameRequired": "Şablon adı zorunludur.",
            "languageSelectionRequired": "Dil seçimi zorunludur.",
            "templateContentRequired": "Şablon içeriği zorunludur.",
            "note": "Not:",
            "fileFormatsAndSizes": "Aşağıda, yükleme için kabul edilen dosya formatları ve boyutları belirtilmiştir:",
            "image": "Resim",
            "jpegPng": "JPEG ve PNG",
            "maxSize": "Maksimum boyut",
            "updateGroup": "Grubu Güncelle",
            "addGroup": "Grup Ekle",
            "groupInfo": "Grup Bilgileri",
            "groupName": "Grup Adı",
            "groupDesc": "Grup Açıklaması",
            "active": "Aktif",
            "save": "Kaydet",
            "groupNamePlaceholder": "Grup Adı Girin",
            "groupDescPlaceholder": "Grup Açıklaması Girin",
            "requiredFields": "Lütfen zorunlu alanları doldurunuz",
            "groupSavedSuccess": "Grup bilgileri başarılı bir şekilde kaydedilmiştir.",
            "send": "Gönder",
            "customerConfirmationError": "Kaydedilmemiş verileriniz var. Kaydetmeden devam etmek istiyor musunuz?",
            "warning": "Uyarı!",
            "dataLoading": "Veriler yükleniyor...",
            "turkish": "Türkçe",
            "backToTemplate": "Mesaj Şablonları",
            "createTemplateText": "Kaydet Ve Onaya Gönder",
            "importExcel": "Excel'den İçeri Aktar",
            "importExcelFile": "Excel Dosyası Yükle",
            "customerSaveSuccess": "Müşteri bilgileri başarılı bir şekilde kaydedilmiştir.",
            "customerSaveError": "Müşteri bilgileri kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.",
            "requiredFieldsError": "Lütfen zorunlu alanları doldurunuz.",
            "clientApprovedText": "Kullanıcı iletişim izni whatsapp üzerinden alındı.",
            "clientApprovedTextByAdmin": "Kullanıcının iletişim izni admin tarafından verildi.",
            "clientDenyText": "Kullanıcı iletişim izni whatsapp üzerinden iptal edildi",
            "clientDenyByAdminText": "Kullanıcı iletişim izni admin tarafından kaldırıldı.",
            "denyClient": "İletişim İznini Kaldır",
            "denyClientConfirmTitle": "Müşterinin iletişim izni kaldırılacaktır.",
            "denyClientConfirmContent": "Müşteri iletişim izni kaldırıldığında kullanıcıya mesaj gönderemeyeceksiniz. Devam etmek istiyor musunuz ?",
            "apiSuccess": "İşlem başarıyla tamamlandı",
            "apiError": "Bir hata oluştu. Lütfen tekrar deneyin",
            "allReceiver": "Tüm Alıcılar",
            "pleaseSelect": "Lütfen Seçiniz",
            "downloadSampleExcel": "Örnek Dosya İndir",
            "emptyExcelError": "Excel dosyası boş. Lütfen geçerli bir dosya yükleyin.",
            "excelSuccessLoad": "Başarılı: Dosya başarıyla yüklendi",
            "matchExcelColumns": "Excel Kolonlarını Eşleştir",
            "excelColumn": "Excel Satırı",
            "matchedExcelColumn": "Eşleştirilecek Alan",
            "sampleExcelData": {
                "customerName": "Test Müşteri",
                "email": "test@example.com",
                "phone": "1234567890",
                "customerGroup": "Test Group",
                "countryCode": "90",
            },
            "sampleExcelFileName": "Müşteri Listesi",
            "fileName": "Dosya Adı",
            "totalRecord": "Toplam Kayıt Sayısı",
            "missingNumberCount": "Eksik Veri Sayısı",
            "missingPhoneNumberDesc": "Telefon Numarası sütununda eksik veri",
            "uploadFileDetailTitle": "Yüklenen Dosya Detayları",
            "saveExcelCustomersButton": "Aktarımı Başlat",
            "uploadMatchError": "Tüm sütunlar eşleştirilmedi. Lütfen eksik sütunları eşleştirin ve tekrar deneyin.",
            "excelSaveSuccess": "Kaydetme işlemi başarıyla tamamlandı.",
            "excelSaveError": "Veriler kaydedilemedi. Lütfen tekrar deneyin.",
            "forgotPassword": "Şifremi Unuttum",
            "resetPassword": "Şifremi Sıfırla",
            "noUserError": "Bu e-posta adresine kayıtlı bir kullanıcı bulunamadı. Lütfen kontrol edip tekrar deneyin.",
            "emailSendError": "E-posta gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
            "networkError": "Ağ bağlantısı sırasında bir hata oluştu. Lütfen internet bağlantınızı kontrol edip tekrar deneyin.",
            "forgotPassSuccess": "Şifre sıfırlama bağlantısı başarıyla e-posta adresinize gönderildi. Lütfen e-posta kutunuzu kontrol edin.",
            "resetTokenNotFound": "Geçersiz bağlantı. Şifre sıfırlama talebi bulunamadı.",
            "resetTokenExpire": "Bu bağlantının süresi dolmuş. Lütfen yeni bir şifre sıfırlama bağlantısı talep edin.",
            "resetTokenCompleted": "Bu bağlantı zaten kullanılmış. Lütfen yeni bir şifre sıfırlama bağlantısı talep edin",
            "changePassword": "Şifreyi Yenile",
            "newPassword": "Yeni şifrenizi girin",
            "newPasswordAgain": "Yeni şifrenizi tekrar girin",
            "passwordMatchError": "Şifreler eşleşmiyor. Lütfen tekrar deneyin",
            "resetPasswordCompletedSuccess": "Şifreniz başarıyla güncellendi. Şimdi giriş yapabilirsiniz. Birazdan giriş sayfasına yönlendirileceksiniz.",
            "resetPasswordCompletedError": "Şifre yenileme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.",
            "errorCode": "Hata Kodu",
            "errorMessage": "Hata Mesajı",
            "stopTemplateMessage": "Mesaj almak istemiyorsanız, IPTAL yazıp cevaplayabilirsiniz",
            "creditOverflow": "En fazla {total} kredi kullanılabilir.",
            "creditOverflowApiError": "Seçtiğiniz müşteri sayısı, kredi limitinizi aşıyor. Lütfen kredi bakiyenizi artırarak tekrar deneyin.",
            "excelDraggerTitle": "Dosya yüklemek için buraya tıklayın veya sürükleyip bırakın.",
            "excelDraggerContent": "Sadece MS Excel (xls, xlsx) dosya türleri desteklenmektedir.",
            // "campaignSendConfirmation": "Seçtiğiniz gönderici numarası {senderNumber} üzerinden, toplamda {recipentCount} alıcıya aşağıdaki mesaj gönderilecektir. Devam etmek istiyor musunuz?",
            "campaignSendConfirmationTitle": "Mesajlarınız Gönderime Hazır",
            // "campaignSendConfirmationFirst": "Seçtiğiniz gönderici numarası",
            // "campaignSendConfirmationSecond": " üzerinden, toplamda",
            // "campaignSendConfirmationThird": " alıcıya aşağıdaki mesaj gönderilecektir. Devam etmek istiyor musunuz?",
            "campaignSendConfirmationFirst": "Toplam",
            "campaignSendConfirmationSecond": " alıcıya kampanya mesajlarınız gönderilecektir. Gönder butonuna tıklayarak işleminizi tamamlayabilirsiniz.",
            "totalRecipent": "Toplam Alıcı",
            "totalSent": "Toplam Gönderilen",
            "totalDelivered": "Toplam Teslim Edilen",
            "totalNotSent": "Toplam İletilemeyen",
            "totalRead": "Toplam Okunan",
            "clientStatusUpdateSuccess": "Müşteri durumu başarıyla değiştirildi",
            "clientStatusUpdateError": "Müşteri durumu değiştirilemedi. Lütfen tekrar deneyin",
            "makePassive": "Pasife Al",
            "makeActive": "Aktife Al",
            "makePassiveConfirmMessage": "Müşteri durumu Pasif olarak değiştirilecektir. Devam etmek istiyor musunuz?",
            "makeActiveConfirmMessage": "Müşteri durumu Aktif  olarak değiştirilecektir. Devam etmek istiyor musunuz?"
        }
    }
};

const getInitialLanguage = () => {
    const firmId = localStorage.getItem(FIRM_ID);
    return firmLanguageMap[firmId] || 'en'; // default to English if no match
};


i18n.use(initReactI18next).
    init(
        {
            resources,
            lng: localStorage.getItem('Language'), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
            // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
            // if you're using a language detector, do not define the lng option

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        }
    );


export default i18n;
